<template>
  <div class="trade">
    <div class="fix-header">
      <a
        v-if="!(tableNum && isDone)"
        class="fix-header-back-icon"
        @click="closed"
      >
        <i class="fas fa-chevron-left"></i>
      </a>
      <p class="fix-header-title">{{ $t("trade.orderinformation") }}</p>
    </div>
    <div v-if="isSale" class="content" style="overflow: hidden">
      <div class="trade-status">
        <template>
          <img src="../imgs/done.png" :alt="trade.OnlineOrderStatusText" />
        </template>
      </div>
      <div class="flex items-center flex-column">
        <p v-if="store.InDoneTitle" class="instore-msg">
          {{ store.InDoneTitle }}
        </p>
        <p v-else class="instore-msg">{{ $t("trade.accepted") }}</p>
        <p class="instore-tablenum">
          {{ $t("trade.tablenumber") }}&nbsp;&nbsp;{{ tableNum }}
        </p>
      </div>
      <hr />
      <p class="checkout-container-title">{{ $t("trade.orderdetail") }}</p>
      <div
        class="trade-food-container"
        v-for="item in trade.Items"
        :key="item.ItemId"
      >
        <div class="trade-food">
          <div class="my-2">
            <div class="trade-food-header">
              <p class="trade-food-name inline-block">{{ item.FoodName }}</p>
              <div class="flex items-center">
                <p class="trade-food-qty inline-block">{{ item.Qty }}</p>
                <p class="trade-food-total">NT {{ item.Total }}</p>
              </div>
            </div>
            <div class="trade-food-taste">{{ item.FoodTaste }}</div>
            <div v-for="combo in item.Combos" :key="combo.ComboId">
              <div class="trade-food-combo">
                <div class="flex items-center">
                  <div class="trade-food-combo-icon">
                    <i class="fas fa-circle"></i>
                  </div>
                  <p>{{ combo.FoodName }}</p>
                </div>
                <p v-if="combo.Total != 0">+{{ combo.Total }}</p>
              </div>
              <div class="trade-food-taste">{{ combo.FoodTaste }}</div>
            </div>
            <div class="trade-food-memo" v-if="item.Memo">
              [{{ item.Memo }}]
            </div>
          </div>
        </div>
      </div>
      <hr />
      <div class="checkout-instore-total">
        <label class="checkout-container-title" for="">{{
          $t("trade.total")
        }}</label>
        <p>NT {{ trade.Total }}</p>
      </div>
      <hr />
      <p class="checkout-container-title">{{ $t("trade.memo") }}</p>
      <p id="salNumber">{{ trade.Memo }}</p>
      <hr />
      <p class="checkout-container-title">{{ $t("trade.member") }}</p>
      <div class="checkout-text-group">
        <label for="customerName">{{ $t("trade.name") }}</label>
        <p id="customerName">{{ trade.CustomerName }}</p>
      </div>
      <hr />
      <p class="checkout-container-title">
        {{ $t("trade.receiptinformation") }}
      </p>
      <div class="checkout-text-group">
        <label for="uniformNumber">{{ $t("trade.uniformnumber") }}</label>
        <p id="uniformNumber">{{ trade.UniformNumber }}</p>
      </div>
      <div class="checkout-text-group">
        <label for="uniformNumber">{{ $t("trade.vehiclenumber") }}</label>
        <p id="uniformNumber">{{ trade.VehicleNumber }}</p>
      </div>
      <hr />
      <button
        v-if="!(tableNum && isDone)"
        class="btn btn-full btn-border mt-5"
        @click="goMenu"
      >
        {{ $t("trade.backtomenu") }}
      </button>
    </div>
    <div v-else class="content" style="overflow: hidden">
      <div class="trade-status">
        <template v-if="trade.OnlineOrderStatusType == 0">
          <img src="../imgs/waiting.png" :alt="trade.OnlineOrderStatusText" />
        </template>
        <template v-else-if="trade.OnlineOrderStatusType == 1">
          <img src="../imgs/done.png" :alt="trade.OnlineOrderStatusText" />
        </template>
        <template v-else-if="trade.OnlineOrderStatusType == 2">
          <img src="../imgs/reject.png" :alt="trade.OnlineOrderStatusText" />
        </template>
        <template v-else-if="trade.OnlineOrderStatusType == 3">
          <img src="../imgs/done.png" :alt="trade.OnlineOrderStatusText" />
        </template>
        <p style="margin-top: 10px">{{ trade.OnlineOrderStatusText }}</p>
      </div>
      <div v-if="!isTable" class="sale-done-title">
        <p>{{ store.OutDoneTitle }}</p>
      </div>
      <div v-else class="sale-done-title">
        <p>{{ store.InDoneTitle }}</p>
      </div>
      <div v-if="trade.OnlineOrderStatusType == 2" class="trade-reason">
        <p>{{ $t("trade.rejectreason") }}</p>
        <p>{{ trade.OnlineOrderRejectReason }}</p>
      </div>
      <div
        class="trade-reason"
        style="flex-direction: row; justify-content: center"
        v-else-if="!isTable && !isSale && trade.OnlineOrderStatusType == 3"
      >
        <p>{{ $t("trade.completetime") }}</p>
        <p style="margin-left: 20px">{{ trade.SalTimeText }}</p>
      </div>
      <div
        v-if="
          !isTable &&
          !isSale &&
          (trade.OnlineOrderStatusType == 0 || trade.OnlineOrderStatusType == 1)
        "
      >
        <div
          class="trade-reserve"
          style="flex-direction: row; justify-content: center"
        >
          <span>{{ $t("trade.reservetime") }}</span>
          <p style="margin-left: 20px">{{ trade.EstimatedPickupTimeText }}</p>
        </div>
      </div>
      <div v-else>
        <div
          class="trade-reserve"
          style="flex-direction: row; justify-content: center"
        >
          <span>{{ $t("trade.tablenum") }}</span>
          <p style="margin-left: 16px">{{ tableNum }}</p>
        </div>
      </div>
      <hr
        v-if="
          trade.OnlineOrderStatusType != 0 && trade.OnlineOrderStatusType != 1
        "
      />
      <div class="checkout-text-group">
        <label for="salNumber">{{ $t("trade.ordernumber") }}</label>
        <p id="salNumber">{{ trade.SalNumber }}</p>
      </div>
      <div class="checkout-text-group">
        <label for="salNumber">{{ $t("trade.createtime") }}</label>
        <p id="salNumber">{{ trade.OnlineOrderTimeText }}</p>
      </div>
      <div class="checkout-text-group">
        <label for="salNumber">{{ $t("trade.memo") }}</label>
        <p id="salNumber">{{ trade.Memo }}</p>
      </div>
      <div
        v-if="trade.OnlineOrderLogs && trade.OnlineOrderLogs.length > 0"
        class="checkout-text-group"
        style="align-items: flex-start"
      >
        <label for="salNumber">{{ $t("trade.changelogs") }}</label>
        <div class="logs-group">
          <p
            v-for="(log, index) in trade.OnlineOrderLogs"
            :key="index"
            id="salNumber"
          >
            {{ log.Memo }}
          </p>
        </div>
      </div>
      <hr />

      <p class="checkout-container-title">{{ $t("trade.orderdetail") }}</p>
      <div
        class="trade-food-container"
        v-for="item in trade.Items"
        :key="item.ItemId"
      >
        <div class="trade-food">
          <div class="my-2">
            <div class="trade-food-header">
              <p class="trade-food-name inline-block">{{ item.FoodName }}</p>
              <div class="flex items-center">
                <p class="trade-food-qty inline-block">{{ item.Qty }}</p>
                <p class="trade-food-total">NT {{ item.Total }}</p>
              </div>
            </div>
            <div class="trade-food-taste">{{ item.FoodTaste }}</div>
            <div v-for="combo in item.Combos" :key="combo.ComboId">
              <div class="trade-food-combo">
                <div class="flex items-center">
                  <div class="trade-food-combo-icon">
                    <i class="fas fa-circle"></i>
                  </div>
                  <p>{{ combo.FoodName }}</p>
                </div>
                <p v-if="combo.Total != 0">+{{ combo.Total }}</p>
              </div>
              <div class="trade-food-taste">{{ combo.FoodTaste }}</div>
            </div>
            <div class="trade-food-memo" v-if="item.Memo">
              [{{ item.Memo }}]
            </div>
          </div>
        </div>
      </div>
      <hr />
      <p class="checkout-container-title">{{ $t("trade.member") }}</p>
      <div class="checkout-text-group">
        <label for="customerName">{{ $t("trade.name") }}</label>
        <p id="customerName">{{ trade.CustomerName }}</p>
      </div>
      <hr />
      <p class="trade-total">
        {{ $t("trade.total") + " NT" }} <span>{{ trade.Total }}</span>
      </p>
      <button
        v-if="!(tableNum && isDone)"
        class="btn btn-full btn-border mt-5"
        @click="goMenu"
      >
        {{ $t("trade.backtomenu") }}
      </button>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      times: 0,
      intervalId: undefined,
      trade: {},
      inStoreSalId: undefined,
      inStoreSalTempId: undefined,
      store: {},
    };
  },
  computed: {
    isSale() {
      return this.inStoreSalId && this.tableNum;
    },
    isTable() {
      return this.inStoreSalTempId && this.tableNum;
    },
    tableNum() {
      return localStorage.getItem("tableNum");
    },
    isDone() {
      return this.trade.OnlineOrderStatusType == 3;
    },
  },
  watch: {
    times(val) {
      if (val >= 15 && this.intervalId) {
        clearInterval(this.intervalId);
      }
    },
    "trade.OnlineOrderStatusType"(newValue, oldValue) {
      if (oldValue == 0 && newValue != 0) {
        clearInterval(this.intervalId);
        const vm = this;
        this.$api.historyOrders.get(this.trade.SalId).then((data) => {
          vm.trade = data;
          vm.$store.dispatch("updateLoading", false);
        });
      }
    },
  },
  methods: {
    closed() {
      if (localStorage.getItem("orderFromInStore")) {
        this.goMenu();
        localStorage.removeItem("orderFromInStore");
      } else {
        this.goMenu();
      }
    },
    goMenu() {
      let routeData = this.$func.getRoute();
      this.$router.push({
        name: routeData.InStore + "Menu",
        params: {
          routeid: routeData.RouteId,
        },
      });
    },
    startInterval() {
      if (this.trade.EatType == 0 && !localStorage.getItem("salTempId")) return;
      this.times = 0;
      this.intervalId = setInterval(this.updateStatus, 5000);
    },
    updateStatus() {
      if (!this.trade || typeof this.trade !== "object") {
        return;
      }
      const id = this.$route.params.id;
      this.times = this.times + 1;
      const vm = this;
      this.$api.orders
        .getStatus(id)
        .then((data) => {
          vm.$store.dispatch("updateLoading", false);
          if (data) {
            vm.trade.OnlineOrderStatusType = data;
          }
        })
        .catch((error) => {
          vm.$swal(error.data.Message);
        });
    },
  },
  mounted() {
    window.scrollTo(0, 0);
    const salId = this.$route.params.id;
    const vm = this;
    this.store = JSON.parse(
      window.localStorage[`store.${this.$route.params.keyid}`]
    );

    this.$api.historyOrders
      .get(salId)
      .then((data) => {
        vm.trade = data;
        vm.inStoreSalId = localStorage.getItem("salId");
        vm.inStoreSalTempId = localStorage.getItem("salTempId");
        vm.$store.dispatch("updateLoading", false);
        if (data.OnlineOrderStatusType == 0) {
          vm.startInterval();
        }
      })
      .catch((error) => {
        vm.$swal(error.data.Message);
        let routeData = this.$func.getRoute();
        vm.$router.push({
          name: routeData.InStore + "Menu",
          params: {
            routeid: routeData.RouteId,
          },
        });
      });
  },
  created() {},
  destroyed() {
    clearInterval(this.intervalId);
  },
};
</script>
