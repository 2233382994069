<template>
  <div>
    <div class="order-background" :class="[bgTrigger ? '' : 'hideOrder']"></div>
    <OrderEdit
      @closed="bgTrigger = isOrderShow = false"
      :currentFood="currentFood"
      :editingItemId="editingItemId"
      :trigger="isOrderShow"
      :store="store"
      @saved="saveCart"
    ></OrderEdit>
    <TakeAwayTime
      class="time-display"
      :class="[isTimeShow ? 'flexTime' : 'hideTime']"
      :takeTime="takeTime"
      :workDaysArr="workDaysArr"
      :waitingSetting="waitingSetting"
      :defaultDate="selectedDate"
      :defaultTime="selectedTime"
      :trigger="isTimeShow"
      :hasIntervalCounts="hasIntervalCounts"
      @closed="isTimeShow = false"
      @saved="changeTime"
    ></TakeAwayTime>
    <div class="checkout">
      <div class="fix-header">
        <a class="fix-header-back-icon" @click="back">
          <i class="fas fa-chevron-left"></i>
        </a>
        <p class="fix-header-title">{{ $t("checkout.cart") }}</p>
      </div>

      <div class="content">
        <div v-if="workDaysArr.length > 0" class="menu-store-group my-3">
          <div
            style="
              display: flex;
              flex-direction: column;
              align-items: center;
              border: 1px solid #16418f;
              background-color: #c9dcff47;
              border-radius: 5px 5px 5px 5px;
              width: 100%;
            "
          >
            <div class="p-1">
              <span style="font-size: 20px; font-weight: 900">{{
                $t("checkout.expectedpickuptime")
              }}</span>
            </div>

            <hr
              class="m-0"
              style="
                width: 100%;
                height: 0.5px;
                background-color: rgba(22, 65, 143, 0.2);
              "
            />
            <div
              v-if="hasEdit"
              class="pointer"
              style="
                display: flex;
                flex-direction: column;
                align-items: center;
                width: 100%;
                padding: 15px 0px;
              "
              @click="editPickUpTime"
            >
              <span style="font-size: 20px; font-weight: 700">{{
                expectTakeTime.date
              }}</span>
              <span style="font-size: 28px; font-weight: 500">{{
                expectTakeTime.time
              }}</span>
            </div>
            <div
              v-else
              style="
                display: flex;
                flex-direction: column;
                align-items: center;
                width: 100%;
                padding: 15px 0px;
              "
            >
              <span style="font-size: 28px; font-weight: 500">{{
                overTakeTime
              }}</span>
            </div>
          </div>
        </div>
        <div class="checkout-store">
          <div class="checkout-store-title">
            <div class="checkout-store-icon"><i class="fas fa-store"></i></div>
            <p class="checkout-store-text">{{ store.StoreName }}</p>
          </div>
          <button
            class="btn btn-border checkout-food-delete-all"
            @click.prevent="removeAllItem()"
          >
            {{ $t("checkout.cancel") }}
          </button>
        </div>
        <div class="checkout-announcement" v-if="store.IsShowCheckAnnouncement">
          <div class="checkout-announcement-title">
            <i class="fas fa-info-circle"></i>
            <span class="ml-1">{{ $t("checkout.notice") }}</span>
          </div>
          <p>{{ store.CheckAnnouncement }}</p>
        </div>

        <div class="checkout-order-detail">
          <p class="checkout-container-title">
            {{ $t("checkout.orderdetails") }}
          </p>
        </div>
        <div v-for="item in cart.Items" :key="item.ItemId">
          <div class="checkout-food" @click="editFood(item)">
            <div class="checkout-food-header">
              <div class="flex items-center">
                <button
                  class="checkout-food-delete"
                  @click="removeItem($event, item.ItemId)"
                >
                  <i class="fas fa-trash-alt"></i>
                </button>
                <p class="checkout-food-qty inline-block">{{ item.Qty }}</p>
                <p class="checkout-food-name inline-block">
                  {{ item.FoodName }}
                </p>
              </div>
              <p class="checkout-food-total">${{ item.Total }}</p>
            </div>
            <div class="checkout-food-taste" v-if="item.FoodTaste">
              {{ item.FoodTaste }}
            </div>
            <div
              v-for="(combo, index) in item.Combos"
              :key="`${combo.ComboId}_${index}`"
            >
              <div class="checkout-food-combo">
                <div class="flex">
                  <div class="checkout-food-combo-icon">
                    <i class="fas fa-circle"></i>
                  </div>
                  <p>{{ combo.FoodName }}</p>
                </div>
                <p v-if="combo.Total != 0">+{{ combo.Total }}</p>
              </div>
              <div class="checkout-food-taste" v-if="combo.FoodTaste">
                {{ combo.FoodTaste }}
              </div>
            </div>
            <div class="checkout-food-memo" v-if="item.Memo">
              <div class="flex">
                <div class="checkout-food-memo-content">[{{ item.Memo }}]</div>
              </div>
            </div>
          </div>
          <hr />
        </div>

        <div class="checkout-total">
          <p class="checkout-container-title">{{ $t("checkout.total") }}</p>
          <p class="checkout-total-text">
            NT<span>{{ cart.Total }}</span>
          </p>
        </div>

        <hr v-if="store.HasTitleMemo" />
        <p
          v-if="store.HasTitleMemo"
          class="checkout-container-title"
          for="memo"
        >
          {{ $t("checkout.memo") }}
        </p>
        <textarea
          v-if="store.HasTitleMemo && !store.TradeMemoTitle"
          class="checkout-memo-input"
          id="memo"
          rows="3"
          v-model="cart.Memo"
          :placeholder="$t('checkout.allergiesoringredients')"
        ></textarea>
        <textarea
          v-else-if="store.HasTitleMemo && store.TradeMemoTitle"
          class="checkout-memo-input"
          id="memo"
          rows="3"
          v-model="cart.Memo"
          :placeholder="store.TradeMemoTitle"
        ></textarea>
        <hr />
        <div class="checkout-member">
          <p class="checkout-container-title">{{ $t("checkout.member") }}</p>
          <div class="checkout-text-group">
            <label for="memberName">{{ $t("checkout.name") }}</label>
            <p id="memberName">{{ member.Name }}</p>
          </div>
          <div class="checkout-text-group">
            <label for="cellphone">{{ $t("checkout.phonenumber") }}</label>
            <p id="cellphone">{{ member.Cellphone }}</p>
          </div>
        </div>
        <hr />
        <div>
          <div class="checkout-invoice">
            <p class="checkout-container-title">{{ $t("checkout.receipt") }}</p>
            <div class="checkout-invoice-group">
              <div class="checkout-group" v-if="store.IsShowUniformNumber">
                <input
                  class="uniform-number-input"
                  type="text"
                  v-model="cart.UniformNumber"
                  :placeholder="$t('checkout.uniformnumber')"
                />
              </div>
              <div class="checkout-group" v-if="store.IsShowVehicleNumber">
                <div class="vehicle-number-container">
                  <div class="vehicle-number-slash">/</div>
                  <input
                    class="vehicle-number-input"
                    type="text"
                    v-model="cart.VehicleNumber"
                    :placeholder="$t('checkout.vehiclenumber')"
                  />
                </div>
              </div>
            </div>
          </div>
          <hr />
        </div>

        <div class="checkout-pay">
          <p class="checkout-container-title">
            {{ $t("checkout.paymentmethod") }}
          </p>
          <div class="checkout-radio">
            <input
              type="radio"
              id="payOnTake"
              name="payment"
              :value="0"
              v-model="selectedPayment"
            />
            <label for="payOnTake"> {{ $t("checkout.payatstore") }}</label>
          </div>
          <div v-if="store.HasLinePay" class="checkout-radio">
            <input
              type="radio"
              id="linePay"
              name="payment"
              :value="1"
              v-model="selectedPayment"
            />
            <label for="linePay">
              <img src="../imgs/linepay.svg" alt="" />
            </label>
          </div>
        </div>
      </div>

      <div class="checkout-btn">
        <button class="btn btn-full btn-border" @click="check">
          {{ $t("checkout.submitorder") }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import $ from "jquery";
import TakeAwayTime from "../components/TakeAwayTime.vue";
import OrderEdit from "../components/OrderEdit.vue";

export default {
  data() {
    return {
      store: {},
      cart: {},
      member: {},
      isTimeShow: false,
      takeTime: {
        dateTypes: [],
        timeTypes: [],
        currentNum: 0,
      },
      expectTakeTime: {
        date: null,
        time: null,
      },
      workDaysArr: [],
      waitingSetting: {},
      weeks: this.$enum.weekType.getAll(),
      selectedDate: {
        value: 0,
        day: {
          value: 0,
          text: "",
          word: "",
        },
        text: "",
      },
      selectedTime: {
        value: 0,
        text: "",
        time: "",
      },
      restDates: 0,
      hasEdit: true,
      isEdit: true,
      overTakeTime: "",
      isOrderShow: false,
      currentFood: {},
      editingItemId: null,
      bgTrigger: false,
      currentLang: {},
      selectedPayment: 0,
    };
  },
  components: {
    TakeAwayTime,
    OrderEdit,
  },
  computed: {
    hasIntervalCounts() {
      return this.store.OnlineOrderIntervalCounts > 0;
    },
  },
  watch: {
    "cart.Items"(value) {
      if (value.length == 0) {
        this.$router.push({
          name: "Menu",
          params: { keyid: this.$route.params.keyid },
        });
      }
    },
    // currentLang(value) {
    //   this.$i18n.locale = value.lang.toLowerCase();
    //   this.weeks = this.$enum.weekType.getAll();
    //   this.workDaysArr.forEach((workDay) => {
    //     if (workDay.RestKeyword) workDay.Times[0] = this.$t("checkout.closedday");
    //     workDay.Name = this.$enum.weekType.getByCht(workDay.Text).text;
    //   });
    //   this.$api.stores
    //     .getStatus(this.$route.params.keyid, {
    //       language_type: this.currentLang.value,
    //     })
    //     .then((data) => {
    //       console.log(data);
    //       this.store = data;
    //       this.$store.dispatch("updateLoading", false);
    //     })
    //     .catch((error) => {
    //       this.$swal(error.data.Message);
    //     });
    //   this.setExpectTakeTime(false);
    //   this.takeTime.timeTypes.forEach((timeType) => {
    //     if (timeType.text.length > 7) {
    //       timeType.text.slice(0, 8);
    //       timeType.text += ` (${this.$t("checkout.thistimeslotisfullybooked")})`;
    //     }
    //   });
    //   if (this.selectedTime.text.length == 0) {
    //     this.expectTakeTime = this.$t("checkout.closedtoday");
    //   }
    // },
    isOrderShow(value) {
      if (value) {
        $("body").css("overflow", "hidden");
        window.addEventListener("click", this.clickOnBackground);
      } else {
        $("body").css("overflow", "auto");
        window.removeEventListener("click", this.clickOnBackground);
      }
    },
    isTimeShow(value) {
      if (value) {
        $(".fix-header").css("display", "none");
        $(".time-display").css("overflow", "auto");
        $("body").css("overflow", "hidden");
      } else {
        $(".fix-header").css("display", "flex");
        $(".time-display").css("overflow", "hidden");
        $("body").css("overflow", "auto");
      }
    },
  },
  methods: {
    // setCurrentLanguage(lang) {
    //   localStorage.setItem("currentLang", lang);
    //   this.$store.dispatch("setLanguage", lang);
    //   this.currentLang = this.$enum.langType.getByLang(lang);
    // },
    clickOnBackground(e) {
      if (e.target.classList.contains("order-background")) {
        this.bgTrigger = false;
        this.isOrderShow = false;
      }
    },
    editFood(item) {
      this.bgTrigger = true;
      this.$api.foods
        .get(item.FoodId)
        .then((data) => {
          this.editingItemId = item.ItemId;
          this.currentFood = data;
          this.isOrderShow = true;
          this.$store.dispatch("updateLoading", false);
        })
        .catch((error) => {
          this.$swal(error.data.Message);
          this.bgTrigger = false;
        });
    },
    saveCart(cartItem) {
      let cart = this.$cookie.cart.get(this.$route.params.keyid);
      let itemIndex = cart.Items.findIndex((i) => {
        return cartItem.ItemId == i.ItemId;
      });
      cart.Items[itemIndex] = cartItem;
      this.$cookie.cart.set(this.$route.params.keyid, cart);
      this.cart = this.$cookie.cart.get(this.$route.params.keyid);
      this.bgTrigger = false;
      this.isOrderShow = false;
    },
    changeTime(date, time) {
      this.selectedDate = date;
      this.selectedTime = time;
      this.setExpectTakeTime(true);
      this.isTimeShow = false;
    },
    editPickUpTime() {
      if (!this.hasEdit) {
        return;
      }
      this.restDates = 0;
      this.setReserveDatas(this.isEdit);
      this.isTimeShow = true;
    },
    setExpectTakeTime(isChange) {
      if (this.hasEdit) {
        let oldDate = JSON.parse(
          localStorage.getItem(`selectedDate.${this.$route.params.keyid}`)
        );
        let oldTime = JSON.parse(
          localStorage.getItem(`selectedTime.${this.$route.params.keyid}`)
        );
        let oldLength = JSON.parse(
          localStorage.getItem(`timesLength.${this.$route.params.keyid}`)
        );

        let setDate = "";
        let setTime = "";

        if (oldDate && oldTime && oldLength && !isChange) {
          if (oldDate.text) {
            oldDate.day.word = this.$enum.weekType.getByCht(
              oldDate.day.text
            ).text;
          }
          this.selectedDate = oldDate;
          if (oldTime.text.length == 7 && !oldTime.isOrderFull != true) {
            this.selectedTime = oldTime;
          } else {
            localStorage.setItem(
              `selectedTime.${this.$route.params.keyid}`,
              JSON.stringify(this.selectedTime)
            );
          }

          let currentDate = this.takeTime.dateTypes.find((d) => {
            return (
              d.value == this.selectedDate.value &&
              d.text == this.selectedDate.text
            );
          });
          let currentTime = this.takeTime.timeTypes.find((t) => {
            return (
              t.value == this.selectedTime.value &&
              t.text == this.selectedTime.text
            );
          });

          if (!currentDate || (!currentTime && this.selectedDate.value == 0)) {
            this.selectedDate = this.takeTime.dateTypes[0];
            this.selectedTime = this.takeTime.timeTypes.find((timeType) => {
              return timeType.text.length == 7;
            }); //選擇最接近的非滿單的時間選項
          }

          if (
            currentDate &&
            currentTime &&
            this.selectedDate.text == currentDate.text &&
            oldLength == this.takeTime.timeTypes.length
          ) {
            if (this.selectedDate.value == 0) {
              setDate = this.$t("checkout.today");
            } else {
              setDate = `${this.selectedDate.text
                .substring(5, 7)
                .padStart(2, "0")}/${this.selectedDate.text
                .substring(8, 10)
                .padStart(2, "0")} (${this.selectedDate.day.word})`;
            }
            if (this.selectedTime.value == 0) {
              if (this.waitingSetting.WaitingType == 0) {
                setTime = `${this.store.WaitingNum}${this.$t(
                  "checkout.minuteslater"
                )}`;
              } else if (this.waitingSetting.WaitingType == 1) {
                setTime = `${this.store.WaitingNum}${this.$t(
                  "checkout.hourslater"
                )}`;
              }
            } else {
              setTime = `${this.selectedTime.text}`;
            }
            this.expectTakeTime.date = setDate;
            this.expectTakeTime.time = setTime;
            return;
          }
        }

        if (this.selectedDate.value == 0) {
          setDate = this.$t("checkout.today");
        } else {
          setDate = `${this.selectedDate.text
            .substring(5, 7)
            .padStart(2, "0")}/${this.selectedDate.text
            .substring(8, 10)
            .padStart(2, "0")} (${this.selectedDate.day.word})`;
        }
        if (this.selectedTime.value == 0) {
          if (this.waitingSetting.WaitingType == 0) {
            setTime = `${this.store.WaitingNum}${this.$t(
              "checkout.minuteslater"
            )}`;
          } else if (this.waitingSetting.WaitingType == 1) {
            setTime = `${this.store.WaitingNum}${this.$t(
              "checkout.hourslater"
            )}`;
          }
        } else {
          setTime = `${this.selectedTime.text}`;
        }
        this.expectTakeTime.date = setDate;
        this.expectTakeTime.time = setTime;
        localStorage.setItem(
          `selectedDate.${this.$route.params.keyid}`,
          JSON.stringify(this.selectedDate)
        );
        localStorage.setItem(
          `selectedTime.${this.$route.params.keyid}`,
          JSON.stringify(this.selectedTime)
        );
      }
    },
    setReserveDatas(isEdit) {
      this.takeTime = {
        dateTypes: [],
        timeTypes: [],
        currentNum: 0,
      };

      this.waitingSetting = {
        WaitingType: this.store.WaitingType,
        WaitingNum: this.store.WaitingNum,
        WaitingInterval: this.store.WaitingInterval,
        ReserveDays: this.store.ReserveDays,
        LastOrderMinutes: this.store.LastOrderMinutes,
      };
      if (this.store.WaitingInterval <= 0) {
        this.waitingSetting.WaitingInterval = 5;
      }

      let newDate = new Date();

      let firstDate = this.$func.getDate();

      if (this.store.ReserveDays == 0) {
        this.takeTime.dateTypes.push({
          value: 0,
          day: {
            value: newDate.getDay(),
            text: `${this.weeks[newDate.getDay()].cht}`,
            word: `${this.weeks[newDate.getDay()].text}`,
          },
          text: `${firstDate}`,
          listText: this.$t("checkout.today"),
        });
      } else if (this.store.ReserveDays == -1 || this.store.ReserveDays > 0) {
        let days = 30;
        if (this.store.ReserveDays > 0) {
          days = this.store.ReserveDays;
        }
        for (let i = 0; i < days + 1; i++) {
          let nowTime = newDate.getTime() + i * 86400000;
          let nowDay = new Date(nowTime).getDay();
          if (this.workDaysArr[nowDay].Times[0] != "公休") {
            let dateType = {
              value: i,
              day: {
                value: nowDay,
                text: `${this.weeks[nowDay].cht}`,
                word: `${this.weeks[nowDay].text}`,
              },
              text: `${this.$func.getDate(nowTime)}`,
              listText: `${this.$func.getDate(nowTime)}`,
            };
            if (i == 0) {
              dateType.listText = this.$t("checkout.today");
            }
            this.takeTime.dateTypes.push(dateType);
          } else {
            if (i < this.waitingSetting.WaitingNum) {
              this.restDates += 1;
            }
          }
        }
      }

      let workTimes = [];

      let nextOpenDay = {};
      let isToday = false;
      for (let j = 0; j < 7; j++) {
        let day = (j + newDate.getDay()) % 7;
        if (this.workDaysArr[day].Times[0] != "公休") {
          nextOpenDay = this.workDaysArr[day];
          if (j == 0) {
            isToday = true;
          }
          break;
        }
      }
      for (let index = 0; index < nextOpenDay.Times.length; index++) {
        workTimes.push({
          startHr: parseInt(nextOpenDay.Times[index].substring(0, 2)),
          startMin: parseInt(nextOpenDay.Times[index].substring(3, 5)),
          endHr: parseInt(nextOpenDay.Times[index].substring(8, 10)),
          endMin: parseInt(nextOpenDay.Times[index].substring(11, 13)),
        });
        this.intervalCalculate(workTimes[index], index, isToday);
        if (index == nextOpenDay.Times.length - 1) {
          if (this.hasIntervalCounts) {
            let currentDateIndex = this.takeTime.dateTypes.findIndex((date) => {
              return date.value == this.selectedDate.value;
            });
            if (currentDateIndex == -1) {
              this.selectedDate = this.takeTime.dateTypes[0];
              currentDateIndex = 0;
            }
            let dTO = {
              Text: this.takeTime.dateTypes[currentDateIndex].text,
              Times: [],
            };
            this.takeTime.timeTypes.forEach((timeType) => {
              dTO.Times.push(timeType.time.replace(/\s+/g, ""));
            });
            if (this.takeTime.timeTypes.length > 0) {
              this.$api.stores
                .getOrdersStatus(this.$route.params.keyid, dTO)
                .then((data) => {
                  let checkIsOrderFull = data;
                  if (!this.selectedDate.text)
                    this.selectedDate = this.takeTime.dateTypes[0];
                  this.takeTime.timeTypes.forEach((t, i) => {
                    let checkTime = t.time.replace(/\s+/g, "");
                    let isOrderFull = checkIsOrderFull.some((p) => {
                      return p.IsOrderFull && p.Time == checkTime;
                    });
                    if (isOrderFull) {
                      t.isOrderFull = true;
                      if (t.text.length == 7)
                        t.text += ` (${this.$t(
                          "checkout.thistimeslotisfullybooked"
                        )})`;
                    }
                    if (!isEdit && i == this.takeTime.timeTypes.length - 1) {
                      this.selectedTime = this.takeTime.timeTypes.find(
                        (timeType) => {
                          return timeType.text.length == 7;
                        }
                      );
                      this.checkOverTime(
                        index,
                        isToday,
                        workTimes,
                        nextOpenDay,
                        newDate
                      );
                      this.setExpectTakeTime(!isEdit);
                    }
                  });
                });
            } else {
              this.checkOverTime(
                index,
                isToday,
                workTimes,
                nextOpenDay,
                newDate
              );
              this.setExpectTakeTime(!isEdit);
            }
          } else {
            let getTimeSlotIndex = this.getCurrentTimeSlotIndex(workTimes);
            if (getTimeSlotIndex > -1) {
              this.setFastestTime(
                getTimeSlotIndex,
                isToday,
                workTimes,
                newDate
              );
            }
            this.checkOverTime(index, isToday, workTimes, nextOpenDay, newDate);
            this.setExpectTakeTime(!isEdit);
          }
        }

        if (
          this.store.WaitingType == 2 &&
          index == nextOpenDay.Times.length - 1
        ) {
          this.takeTime.dateTypes.splice(
            0,
            this.waitingSetting.WaitingNum - this.restDates
          );
          if (this.takeTime.dateTypes.length > 0) {
            // if (!isEdit) this.selectedDate = this.takeTime.dateTypes[0];
            this.selectedDate = this.takeTime.dateTypes[0];
            let selectedWorkDay = this.workDaysArr.find((d) => {
              return this.selectedDate.day.text == d.Text;
            });
            this.overOpenTime(selectedWorkDay.Times);
          } else {
            this.expectTakeTime = this.$t("checkout.ordertimehaspassed");
            this.hasEdit = false;
            return;
          }
        } else {
          let lastTime = nextOpenDay.Times[nextOpenDay.Times.length - 1];
          let num = null;
          if (this.waitingSetting.WaitingType == 0) {
            num = this.waitingSetting.WaitingNum;
          } else if (this.waitingSetting.WaitingType == 1) {
            num = this.waitingSetting.WaitingNum * 60;
          }
          if (
            newDate.getHours() * 60 +
              newDate.getMinutes() +
              num +
              this.waitingSetting.LastOrderMinutes >
            lastTime.endHr * 60 + lastTime.endMin
          ) {
            this.takeTime.dateTypes.splice(0, 1);
            if (this.takeTime.dateTypes.length > 0) {
              // if (!isEdit) this.selectedDate = this.takeTime.dateTypes[0];
              this.selectedDate = this.takeTime.dateTypes[0];
              let selectedWorkDay = this.workDaysArr.find((d) => {
                return this.selectedDate.day.text == d.Text;
              });
              this.overOpenTime(selectedWorkDay.Times);
            } else {
              this.expectTakeTime = this.$t("checkout.ordertimehaspassed");
              this.hasEdit = false;
              return;
            }
          }
        }
      }
    },
    checkOverTime(index, isToday, workTimes, nextOpenDay, newDate) {
      let waitingNum = 0;
      if (this.store.WaitingType == 0)
        waitingNum = this.store.WaitingNum + this.store.LastOrderMinutes;
      if (this.store.WaitingType == 1)
        waitingNum = this.store.WaitingNum * 60 + this.store.LastOrderMinutes;
      let currentTime =
        newDate.getHours() * 60 + newDate.getMinutes() + waitingNum;
      let endTime = workTimes[index].endHr * 60 + workTimes[index].endMin;
      if (
        this.waitingSetting.ReserveDays == 0 &&
        ((isToday && currentTime > endTime) || !isToday)
      ) {
        this.expectTakeTime = {
          date: null,
          time: null,
        };
        this.overTakeTime = this.$t("checkout.closedtoday");
        localStorage.removeItem(`selectedDate.${this.$route.params.keyid}`);
        localStorage.removeItem(`selectedTime.${this.$route.params.keyid}`);
        localStorage.removeItem(`timesLength.${this.$route.params.keyid}`);
        this.hasEdit = false;
        return;
      } else if (
        this.store.WaitingType == 2 &&
        index == nextOpenDay.Times.length - 1
      ) {
        this.takeTime.dateTypes.splice(
          0,
          this.waitingSetting.WaitingNum - this.restDates
        );
        if (this.takeTime.dateTypes.length > 0) {
          // if (!isEdit) this.selectedDate = this.takeTime.dateTypes[0];
          this.selectedDate = this.takeTime.dateTypes[0];
          let selectedWorkDay = this.workDaysArr.find((d) => {
            return this.selectedDate.day.text == d.Text;
          });
          this.overOpenTime(selectedWorkDay.Times);
        } else {
          this.expectTakeTime = {
            date: null,
            time: null,
          };
          this.overTakeTime = this.$t("checkout.ordertimehaspassed");
          this.hasEdit = false;
          return;
        }
      } else {
        const next = nextOpenDay.Times[nextOpenDay.Times.length - 1];
        let lastTime = {
          startHr: parseInt(next.substring(0, 2)),
          startMin: parseInt(next.substring(3, 5)),
          endHr: parseInt(next.substring(8, 10)),
          endMin: parseInt(next.substring(11, 13)),
        };
        if (
          lastTime.startHr == lastTime.endHr &&
          lastTime.startMin == lastTime.endMin
        ) {
          lastTime = {
            startHr: 0,
            startMin: 0,
            endHr: 24,
            endMin: 0,
          };
        }
        let num = null;
        if (this.waitingSetting.WaitingType == 0) {
          num = this.waitingSetting.WaitingNum;
        } else if (this.waitingSetting.WaitingType == 1) {
          num = this.waitingSetting.WaitingNum * 60;
        }
        if (
          newDate.getHours() * 60 +
            newDate.getMinutes() +
            num +
            this.waitingSetting.LastOrderMinutes >
          lastTime.endHr * 60 + lastTime.endMin
        ) {
          this.takeTime.dateTypes.splice(0, 1);
          if (this.takeTime.dateTypes.length > 0) {
            // if (!isEdit) this.selectedDate = this.takeTime.dateTypes[0];
            this.selectedDate = this.takeTime.dateTypes[0];
            let selectedWorkDay = this.workDaysArr.find((d) => {
              return this.selectedDate.day.text == d.Text;
            });
            this.overOpenTime(selectedWorkDay.Times);
          } else {
            this.expectTakeTime = {
              date: null,
              time: null,
            };
            this.overTakeTime = this.$t("checkout.closedtoday");
            this.hasEdit = false;
            return;
          }
        }
      }
    },
    getCurrentTimeSlotIndex(timeSlots) {
      const now = new Date();
      let waitingNum = 0;
      if (this.store.WaitingType == 0)
        waitingNum = this.store.WaitingNum + this.store.LastOrderMinutes;
      if (this.store.WaitingType == 1)
        waitingNum = this.store.WaitingNum * 60 + this.store.LastOrderMinutes;
      const currentTime = now.getHours() * 60 + now.getMinutes() + waitingNum; // 轉換為分鐘表示的當前時間

      return timeSlots.findIndex((slot) => {
        const slotStart = slot.startHr * 60 + slot.startMin; // 轉換為分鐘表示的開始時間
        const slotEnd = slot.endHr * 60 + slot.endMin; // 轉換為分鐘表示的結束時間

        return currentTime >= slotStart && currentTime < slotEnd; // 判斷當前時間是否在這個時間段內
      });
    },
    setFastestTime(index, isToday, workTimes, newDate) {
      if (
        isToday &&
        this.waitingSetting.WaitingNum > 0 &&
        !this.takeTime.timeTypes.some((t) => t.value == 0)
      ) {
        if (this.store.WaitingType == 0) {
          let currentTime =
            newDate.getHours() * 60 +
            newDate.getMinutes() +
            this.store.WaitingNum;
          let startTime =
            workTimes[index].startHr * 60 + workTimes[index].startMin;
          let endTime = workTimes[index].endHr * 60 + workTimes[index].endMin;
          if (
            (currentTime >= startTime &&
              currentTime < endTime &&
              newDate.getHours() +
                Math.floor(
                  (newDate.getMinutes() +
                    this.waitingSetting.LastOrderMinutes) /
                    60
                ) <
                workTimes[index].endHr) ||
            (newDate.getHours() +
              Math.floor(
                (newDate.getMinutes() + this.waitingSetting.LastOrderMinutes) /
                  60
              ) ==
              workTimes[index].endHr &&
              (newDate.getMinutes() +
                60 +
                this.waitingSetting.LastOrderMinutes) %
                60 <=
                workTimes[index].endMin)
          ) {
            let fastestTime = this.$func.getTime(
              new Date().getTime() + this.waitingSetting.WaitingNum * 60000
            );
            if (this.store.OnlineOrderIntervalCounts == 0) {
              this.takeTime.timeTypes.splice(0, 0, {
                value: 0,
                text: `${this.store.WaitingNum}${this.$t(
                  "checkout.minuteslater"
                )}`,
                time: fastestTime,
              });
            }
          }
        } else if (this.store.WaitingType == 1) {
          let currentTime =
            newDate.getHours() * 60 +
            newDate.getMinutes() +
            this.store.WaitingNum * 60;
          let startTime =
            workTimes[index].startHr * 60 + workTimes[index].startMin;
          let endTime = workTimes[index].endHr * 60 + workTimes[index].endMin;
          if (
            (currentTime >= startTime &&
              currentTime < endTime &&
              newDate.getHours() +
                Math.floor(
                  (newDate.getMinutes() +
                    this.waitingSetting.LastOrderMinutes) /
                    60
                ) <
                workTimes[index].endHr) ||
            (newDate.getHours() +
              Math.floor(
                (newDate.getMinutes() + this.waitingSetting.LastOrderMinutes) /
                  60
              ) ==
              workTimes[index].endHr &&
              (newDate.getMinutes() +
                60 +
                this.waitingSetting.LastOrderMinutes) %
                60 <=
                workTimes[index].endMin)
          ) {
            let fastestTime = this.$func.getTime(
              new Date().getTime() + this.waitingSetting.WaitingNum * 60 * 60000
            );
            if (this.store.OnlineOrderIntervalCounts == 0) {
              this.takeTime.timeTypes.splice(0, 0, {
                value: 0,
                text: `${this.store.WaitingNum}${this.$t(
                  "checkout.hourslater"
                )}`,
                time: fastestTime,
              });
            }
          }
        }
      }
    },
    intervalCalculate(workTime, index, isToday) {
      let startInterval = 0;
      let baseTime = 0;

      if (this.waitingSetting.WaitingType == 0) {
        this.takeTime.currentNum =
          new Date().getMinutes() + this.waitingSetting.WaitingNum;
      } else if (this.waitingSetting.WaitingType == 1) {
        this.takeTime.currentNum =
          new Date().getMinutes() + this.waitingSetting.WaitingNum * 60;
      }

      let startIntervalCal = Math.floor(
        parseInt(
          (this.takeTime.currentNum / this.waitingSetting.WaitingInterval) * 10
        ) / 10
      );

      let selectedWorkDay = this.workDaysArr.find((d) => {
        return this.selectedDate.day.text == d.Text;
      });
      let midnightCheck =
        workTime.endHr == 24 &&
        this.workDaysArr[
          (this.workDaysArr.indexOf(selectedWorkDay) + 1) % 7
        ].Times[0].substring(0, 5) == "00:00";

      for (
        let i = 0;
        i < Math.floor(60 / this.waitingSetting.WaitingInterval) * 24;
        i++
      ) {
        if (isToday && workTime.startHr <= new Date().getHours()) {
          startInterval = startIntervalCal;
          baseTime = new Date().getHours();
        } else {
          startInterval = Math.floor(
            workTime.startMin / this.waitingSetting.WaitingInterval
          );
          baseTime = workTime.startHr;
        }

        if (isToday) {
          startInterval += 1;
        }

        let t = { value: 1, text: "", time: "" };
        if (index > 0) {
          t.value = this.takeTime.timeTypes.length + 1;
        } else {
          t.value = i + 1;
        }

        let hour =
          baseTime +
          Math.floor(
            (this.waitingSetting.WaitingInterval * (startInterval + i)) / 60
          );
        let minute = 0;
        if (
          ((startInterval + i) * this.waitingSetting.WaitingInterval) / 60 <
          1
        ) {
          minute = (startInterval + i) * this.waitingSetting.WaitingInterval;
        } else {
          minute =
            ((startInterval + i) * this.waitingSetting.WaitingInterval) % 60;
        }

        if (
          hour >= 24 ||
          hour > workTime.endHr ||
          (workTime.endMin - this.waitingSetting.LastOrderMinutes < 0 &&
            hour + 1 == workTime.endHr &&
            minute >
              (workTime.endMin - this.waitingSetting.LastOrderMinutes + 60) %
                60 &&
            !midnightCheck) ||
          (hour == workTime.endHr &&
            minute > workTime.endMin - this.waitingSetting.LastOrderMinutes &&
            !midnightCheck)
        ) {
          return i;
        } else {
          t.text = `${hour.toString().padStart(2, "0")} : ${minute
            .toString()
            .padStart(2, "0")}`;
          t.time = `${hour.toString().padStart(2, "0")} : ${minute
            .toString()
            .padStart(2, "0")} : 00`;
          this.takeTime.timeTypes.push(t);
        }
      }
    },
    overOpenTime(times) {
      this.workTimes = [];
      this.takeTime.timeTypes = [];
      for (let index = 0; index < times.length; index++) {
        this.workTimes.push({
          startHr: parseInt(times[index].substring(0, 2)),
          startMin: parseInt(times[index].substring(3, 5)),
          endHr: parseInt(times[index].substring(8, 10)),
          endMin: parseInt(times[index].substring(11, 13)),
        });
        this.tomorrowIntervalCalculate(this.workTimes[index], index);
      }
    },
    tomorrowIntervalCalculate(workTime, index) {
      let selectedWorkDay = this.workDaysArr.find((d) => {
        return this.selectedDate.day.text == d.Text;
      });
      let midnightCheck =
        workTime.endHr == 24 &&
        this.workDaysArr[
          (this.workDaysArr.indexOf(selectedWorkDay) + 1) % 7
        ].Times[0].substring(0, 5) == "00:00";

      for (
        let i = 0;
        i < Math.floor(60 / this.waitingSetting.WaitingInterval) * 24;
        i++
      ) {
        let t = { value: 1, text: "", time: "" };
        if (index > 0) {
          t.value = this.takeTime.timeTypes.length + 1;
        } else {
          t.value = i + 1;
        }
        let hour =
          workTime.startHr +
          Math.floor(
            (this.waitingSetting.WaitingInterval * i + workTime.startMin) / 60
          );
        let minute = 0;
        if (
          (i + 1) * this.waitingSetting.WaitingInterval +
            workTime.startMin / 60 <
          1
        ) {
          minute = i * this.waitingSetting.WaitingInterval + workTime.startMin;
        } else {
          minute =
            (i * this.waitingSetting.WaitingInterval + workTime.startMin) % 60;
        }

        if (
          hour >= 24 ||
          hour > workTime.endHr ||
          (workTime.endMin - this.waitingSetting.LastOrderMinutes < 0 &&
            hour + 1 == workTime.endHr &&
            minute >
              (workTime.endMin - this.waitingSetting.LastOrderMinutes + 60) %
                60 &&
            !midnightCheck) ||
          (hour == workTime.endHr &&
            minute > workTime.endMin - this.waitingSetting.LastOrderMinutes &&
            !midnightCheck) ||
          i == Math.floor(60 / this.waitingSetting.WaitingInterval) * 24 - 1
        ) {
          this.selectedTime = this.takeTime.timeTypes[0];
          this.setExpectTakeTime();
          return;
        } else {
          t.text = `${hour.toString().padStart(2, "0")} : ${minute
            .toString()
            .padStart(2, "0")}`;
          t.time = `${hour.toString().padStart(2, "0")} : ${minute
            .toString()
            .padStart(2, "0")} : 00`;
          this.takeTime.timeTypes.push(t);
        }
      }
    },
    check() {
      this.cart.PayType = this.selectedPayment;
      this.cart.IsLine = this.$liff.isInClient();
      this.cart.ReserveTime = "";

      let checkTime = true;
      let checkClose = false;
      let newDate = new Date();
      let nowDay = this.takeTime.dateTypes.find((d) => {
        return d.text == this.selectedDate.text;
      });

      if (this.hasEdit) {
        if (this.waitingSetting.WaitingType == 0) {
          if (this.selectedTime.value == 0) {
            this.cart.ReserveTime = `${
              this.selectedDate.text
            } ${this.$func.getTime(
              new Date().getTime() + this.waitingSetting.WaitingNum * 60000
            )}`;
          } else {
            this.cart.ReserveTime = `${
              this.selectedDate.text
            } ${this.selectedTime.time.replace(/\s*/g, "")}`;
          }
        } else if (this.waitingSetting.WaitingType == 1) {
          if (this.selectedTime.value == 0) {
            this.cart.ReserveTime = `${
              this.selectedDate.text
            } ${this.$func.getTime(
              new Date().getTime() + this.waitingSetting.WaitingNum * 3600000
            )}`;
          } else {
            this.cart.ReserveTime = `${
              this.selectedDate.text
            } ${this.selectedTime.time.replace(/\s*/g, "")}`;
          }
        } else {
          this.cart.ReserveTime = `${
            this.selectedDate.text
          } ${this.selectedTime.time.replace(/\s*/g, "")}`;
        }

        let openTime = this.workDaysArr.find((w) => {
          return w.Text == nowDay.day.text;
        });
        let lastTime = openTime.Times[openTime.Times.length - 1];

        if (nowDay) {
          let num = 0;
          if (this.waitingSetting.WaitingType == 0) {
            num = this.waitingSetting.WaitingNum;
          } else if (this.waitingSetting.WaitingType == 1) {
            num = this.waitingSetting.WaitingNum * 60;
          }
          checkClose =
            newDate.getHours() * 60 +
              Math.floor(newDate.getMinutes()) +
              num +
              this.waitingSetting.LastOrderMinutes >
            parseInt(lastTime.substring(8, 10)) * 60 +
              parseInt(lastTime.substring(11, 13));
        }

        const selectedTime = this.selectedTime.time.replace(/\s+/g, "");
        const selectedHr = parseInt(selectedTime.substring(0, 2));
        const selectedMin = parseInt(selectedTime.substring(3, 5));
        const selectedSec = parseInt(selectedTime.substring(6, 8));
        if (this.selectedDate.value == 0) {
          checkTime =
            newDate.getHours() * 3600 +
              newDate.getMinutes() * 60 +
              newDate.getSeconds() <
            selectedHr * 3600 + selectedMin * 60 + selectedSec;
        }
      }

      const workTime = this.workDaysArr.find((d) => {
        return d.Text == nowDay.day.text;
      });
      let endTime =
        parseInt(workTime.Times[workTime.Times.length - 1].substring(8, 10)) *
          60 +
        parseInt(workTime.Times[workTime.Times.length - 1].substring(11, 13));
      let currentTime =
        newDate.getHours() * 60 +
        newDate.getMinutes() +
        this.waitingSetting.WaitingNum +
        this.store.LastOrderMinutes;
      let isClosed = currentTime > endTime;

      if ((isClosed || checkClose) && this.selectedDate.value == 0) {
        this.$swal(this.$t("checkout.closedtoday"));
        this.setReserveDatas();
        return;
      }

      if (this.cart.VehicleNumber) {
        this.cart.VehicleNumber = this.cart.VehicleNumber.replace(/\//g, "");
        const regex = /^[0-9A-Z.+-]{7}$/;
        if (!regex.test(this.cart.VehicleNumber)) {
          this.$swal(this.$t("checkout.vehiclenumberisincorrect"));
          return;
        }
      }

      if (checkTime) {
        this.$swal({
          titleText: this.$t("checkout.areyousuretosubmittheorder"),
          icon: "question",
          showCancelButton: true,
        }).then((result) => {
          if (result.value) {
            if (this.cart.VehicleNumber) {
              this.cart.VehicleNumber = "/" + this.cart.VehicleNumber;
            }
            this.$api.checkouts
              .post(this.cart)
              .then((data) => {
                localStorage.removeItem(
                  `selectedDate.${this.$route.params.keyid}`
                );
                localStorage.removeItem(
                  `selectedTime.${this.$route.params.keyid}`
                );
                localStorage.removeItem(
                  `timesLength.${this.$route.params.keyid}`
                );
                this.$cookie.cart.set(
                  this.$route.params.keyid,
                  this.$cookie.cart.getDefault()
                );
                if (this.selectedPayment == 0) {
                  this.$router.push({
                    name: "Trade",
                    params: { keyid: this.$route.params.keyid, id: data },
                  });
                } else if (this.selectedPayment == 1) {
                  this.$api.checkouts
                    .postLinePay(this.store.DeviceId, {
                      TradeId: data,
                      ConfirmURL: `${process.env.VUE_APP_URL}/${this.$route.params.keyid}/paymentconfirming`,
                      CancelURL: `${process.env.VUE_APP_URL}/${this.$route.params.keyid}/paymentcancel`,
                    })
                    .then((lineData) => {
                      localStorage.setItem("lineSalId", data);
                      localStorage.setItem(
                        "lineTransactionId",
                        lineData.TransactionId
                      );
                      const isMobile =
                        /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|Windows Phone|BB10|PlayBook|Silk|Kindle|Mobile/i.test(
                          navigator.userAgent
                        );
                      if (isMobile) {
                        window.location.href = lineData.AppURL;
                      } else {
                        window.location.href = lineData.WebURL;
                      }
                    })
                    .catch((error) => {
                      this.$swal(error.data.Message);
                    });
                }
              })
              .catch((error) => {
                this.$swal(error.data.Message);
              });
          }
        });
      } else {
        this.$swal(this.$t("checkout.businesshourshavechanged"));
        this.setReserveDatas();
        this.setExpectTakeTime();
      }
    },
    back() {
      this.$router.push(`/${this.$route.params.keyid}`);
    },
    removeItem(e, id) {
      e.stopPropagation();
      const vm = this;
      this.$swal({
        titleText: this.$t("checkout.areyousureyouwanttodeletethis"),
        icon: "question",
        showCancelButton: true,
      }).then((result) => {
        if (result.value) {
          vm.cart = vm.$cookie.cart.removeItem(this.$route.params.keyid, id);
        }
      });
    },
    removeAllItem() {
      const vm = this;
      this.$swal({
        titleText: this.$t("checkout.areyousureyouwanttodeletetheentireorder"),
        icon: "question",
        showCancelButton: true,
      }).then((result) => {
        if (result.value) {
          vm.cart = vm.$cookie.cart.removeAllItem(this.$route.params.keyid);
        }
      });
    },
    checkToken() {
      const token = this.$cookie.member.getToken();
      const customer = this.$cookie.customer.get(this.$route.params.keyid);
      if (!token) {
        this.$router.push({
          name: "Login",
          params: { keyid: this.$route.params.keyid, beforepath: "Checkout" },
        });
        return false;
      } else if (!customer || !customer.Name) {
        this.$router.push({
          name: "Join",
          params: { keyid: this.$route.params.keyid, beforepath: "Checkout" },
        });
        return false;
      }
      return true;
    },
  },
  mounted() {
    // let defaultLang = "zh";
    // if (localStorage.getItem("currentLang")) {
    //   defaultLang = localStorage.getItem("currentLang");
    // }
    // this.$i18n.locale = defaultLang;
    // this.currentLang = this.$enum.langType.getByLang(defaultLang);

    this.store = this.$cookie.store.get(this.$route.params.keyid);
    if (this.store.WorkDays) {
      this.workDaysArr = Object.values(this.store.WorkDays);
      this.workDaysArr.forEach((workDay) => {
        const allDayIndex = workDay.Times.findIndex((time) => {
          let t = {
            startHr: parseInt(time.substring(0, 2)),
            startMin: parseInt(time.substring(3, 5)),
            endHr: parseInt(time.substring(8, 10)),
            endMin: parseInt(time.substring(11, 13)),
          };
          return t.startHr == t.endHr && t.startMin == t.endMin;
        });
        if (allDayIndex > -1) {
          let item = "00:00 - 24:00";
          workDay.Times.splice(0, workDay.Times.length);
          workDay.Times.push(item);
        }
        if (workDay.Times[0] == "公休") workDay.RestKeyword = "closedday";
        workDay.Name = this.$enum.weekType.getByCht(workDay.Text).text;
      });
    } else {
      this.hasEdit = false;
    }

    //如果有出現bug先把這段註解掉
    const today = this.$func.getDate();
    const localSelectedDate = localStorage.getItem(
      `lastDate.${this.$route.params.keyid}`
    );
    if (!localSelectedDate || today !== localSelectedDate) {
      localStorage.removeItem(`selectedDate.${this.$route.params.keyid}`);
      localStorage.removeItem(`selectedTime.${this.$route.params.keyid}`);
      localStorage.removeItem(`timesLength.${this.$route.params.keyid}`);
      localStorage.removeItem(`cart.${this.$route.params.keyid}`);
      localStorage.setItem(`lastDate.${this.$route.params.keyid}`, today);
      this.$router.replace({ name: "Menu" });
    }
    //如果有出現bug先把這段註解掉

    if (this.hasEdit) {
      let storageDate = null;
      if (localStorage.getItem(`selectedDate.${this.$route.params.keyid}`)) {
        storageDate = JSON.parse(
          localStorage.getItem(`selectedDate.${this.$route.params.keyid}`)
        );
        if (storageDate.text) {
          storageDate.day.word = this.$enum.weekType.getByCht(
            storageDate.day.text
          ).text;
        }
      }
      let storageTime = null;
      if (localStorage.getItem(`selectedTime.${this.$route.params.keyid}`)) {
        storageTime = JSON.parse(
          localStorage.getItem(`selectedTime.${this.$route.params.keyid}`)
        );
      }
      if (storageDate && storageTime) {
        this.selectedDate = storageDate;
        this.selectedTime = storageTime;

        if (this.selectedDate.value == 0) {
          this.expectTakeTime.date = this.$t("checkout.today");
        } else {
          this.expectTakeTime.date = `${this.selectedDate.text
            .substring(5, 7)
            .padStart(2, "0")}/${this.selectedDate.text
            .substring(8, 10)
            .padStart(2, "0")} (${this.selectedDate.day.word})`;
        }
        if (this.selectedTime.value == 0) {
          this.expectTakeTime.time = `${this.$t("checkout.minuteslater")}`;
        } else {
          this.expectTakeTime.time = `${this.selectedTime.text}`;
        }
        if (this.selectedTime.value == 0) {
          if (this.waitingSetting.WaitingType == 0) {
            this.expectTakeTime.time = `${this.store.WaitingNum}${this.$t(
              "checkout.minuteslater"
            )}`;
          } else if (this.waitingSetting.WaitingType == 1) {
            this.expectTakeTime.time = `${this.store.WaitingNum}${this.$t(
              "checkout.hourslater"
            )}`;
          }
        } else {
          this.expectTakeTime.time = `${this.selectedTime.text}`;
        }
      } else {
        this.selectedDate = this.takeTime.dateTypes[0];
        this.selectedTime = this.takeTime.timeTypes[0];
        // this.$swal(this.$t("checkout.thedatehasbeenupdated"));
      }
    }
    if (this.workDaysArr.length > 0) {
      this.setReserveDatas(true);
    }
  },
  created() {
    window.scrollTo(0, 0);
    if (!this.checkToken()) return;
    this.member = this.$cookie.member.get();
    this.cart = this.$cookie.cart.get(this.$route.params.keyid);
    // const vm = this;
    // this.$api.stores
    //   .getStatus(this.$route.params.keyid, {
    //     language_type: this.$enum.langType.getByLang(
    //       localStorage.getItem("currentLang")
    //     ),
    //   })
    //   .then((data) => {
    //     vm.$store.dispatch("updateLoading", false);
    //     vm.store = data;
    //     vm.cart = vm.$cookie.cart.get(vm.$route.params.keyid);
    //   })
    //   .catch((error) => {
    //     vm.$swal(error.Message);
    //   });
  },
};
</script>
