<template>
  <div>
    <div class="order-background" :class="[bgTrigger ? '' : 'hideOrder']"></div>
    <Order
      @closed="bgTrigger = isOrderShow = false"
      :currentFood="orderFood"
      :trigger="isOrderShow"
      :store="store"
      @saved="addItem"
    ></Order>
    <div class="menu">
      <div class="menu-nav">
        <img class="menu-logo" src="../../imgs/Logo.png" alt="UShow" />
        <div v-if="isMultiLanguage" class="language-selector">
          <div class="language-list-button" @click="openLanguageList">
            <i class="fas fa-globe"></i> {{ currentLang.lang }}
          </div>
          <div class="language-list">
            <div
              v-if="isMultiLanguage"
              class="language-option first-option"
              @click="clickLanguage('zh')"
            >
              中文 (ZH)
            </div>
            <div
              v-if="store.HasEnglish"
              class="language-option"
              @click="clickLanguage('en')"
            >
              English (EN)
            </div>
            <div
              v-if="store.HasJapanese"
              class="language-option"
              @click="clickLanguage('jp')"
            >
              日本語 (JP)
            </div>
            <div
              v-if="store.HasKorean"
              class="language-option"
              @click="clickLanguage('kr')"
            >
              한국어 (KR)
            </div>
            <div
              v-if="store.HasFrench"
              class="language-option"
              @click="clickLanguage('fr')"
            >
              Français (FR)
            </div>
          </div>
        </div>
        <a class="menu-member" @click="memberClick">
          <img class="menu-member-icon" src="../../imgs/user2.png" alt="" />
          <p class="menu-member-text">{{ memberText }}</p></a
        >
      </div>
      <div class="menu-container">
        <div v-if="store.ImageURL" class="menu-store-img-container">
          <img class="menu-store-img" :src="store.ImageURL" alt="店家" />
        </div>
        <div v-else class="image-empty"></div>
        <div class="content-main">
          <div class="menu-store">
            <div class="menu-store-title">
              <div class="menu-store-title-left">
                <div
                  class="menu-store-work-icon"
                  :class="[store.IsOpen ? 'opened' : 'closed']"
                >
                  <i class="fas fa-circle"></i>
                </div>

                <span class="menu-store-name">{{ store.StoreName }}</span>
              </div>
              <div class="bold pr-2 flex item-center" style="font-size: 22px">
                <img
                  src="../../imgs/table.png"
                  alt="table"
                  style="margin-right: 10px"
                />
                {{ table.TableMapName }}
              </div>
            </div>
            <div v-show="store.IsShowIntroduction" class="menu-store-memo">
              <p>{{ store.Introduction }}</p>
            </div>
            <div
              class="menu-instore-ordered-check"
              @click="goCheckout('ordered')"
            >
              <div class="menu-instore-ordered-title">
                <img src="../../imgs/instore.png" alt="instore" />{{
                  $t("menu.orderedmeals")
                }}
              </div>
              <div class="menu-instore-ordered-price">${{ table.Total }}</div>
            </div>
          </div>
          <div class="menu-kind-bar">
            <ul class="menu-kind-bar-main ul-scroll" id="foodKindList">
              <li
                :class="
                  currentFoodKindId == foodKindGroup.FoodKind.FoodKindId
                    ? 'active'
                    : ''
                "
                :id="foodKindGroup.FoodKind.FoodKindId + '_li'"
                v-for="foodKindGroup in foodKindGroups"
                :key="foodKindGroup.FoodKind.FoodKindId"
                @click="changeFoodKind(foodKindGroup.FoodKind.FoodKindId)"
              >
                {{ foodKindGroup.FoodKind.FoodKindName }}
                <div
                  v-if="
                    checkCartItems(
                      foodKindGroup,
                      foodKindGroup.FoodKind.FoodKindId
                    )
                  "
                  class="menu-group-kind-current-Qty"
                >
                  {{
                    checkCartItems(
                      foodKindGroup,
                      foodKindGroup.FoodKind.FoodKindId
                    )
                  }}
                </div>
              </li>
            </ul>
          </div>

          <div class="mt-3" style="position: relative" :key="renderKey">
            <div class="menu-display-mode">
              <div
                class="menu-display-button"
                :class="isGrid ? 'grid' : ''"
              ></div>
              <div
                class="menu-display-list"
                :class="isGrid ? '' : 'using'"
                @click="isGrid = false"
              >
                <i class="fas fa-list-ul"></i>
              </div>
              <div
                class="menu-display-grid"
                :class="isGrid ? 'using' : ''"
                @click="isGrid = true"
              >
                <i class="fas fa-th-large"></i>
              </div>
            </div>
            <div
              class="menu-group"
              v-for="foodKindGroup in foodKindGroups"
              :key="foodKindGroup.FoodKind.FoodKindId"
              :id="foodKindGroup.FoodKind.FoodKindId"
            >
              <div class="menu-group-kind">
                <div class="menu-group-kind-left"></div>
                <div class="menu-group-kind-name">
                  {{ foodKindGroup.FoodKind.FoodKindName }}
                </div>
                <div class="menu-group-kind-right"></div>
              </div>
              <div class="menu-group-container" :class="isGrid ? 'grid' : ''">
                <div
                  class="menu-group-food"
                  :class="isGrid ? 'grid' : ''"
                  v-for="food in foodKindGroup.Foods"
                  :key="food.FoodId"
                  @click="foodClick($event, food)"
                >
                  <div
                    v-if="food.Stock != null && food.Stock > 0"
                    class="menu-group-food-stock"
                  >
                    {{ $t("menu.left") }}{{ food.Stock }}
                  </div>
                  <div
                    class="menu-group-food-text"
                    :style="isGrid ? '' : 'margin-right: 10px;'"
                  >
                    <div>
                      <p
                        class="menu-group-food-name"
                        :class="[
                          food.Stock <= 0 && food.Stock != null
                            ? 'sell-out'
                            : '',
                        ]"
                        :style="isGrid ? 'margin-top: 5px;' : ''"
                      >
                        {{ food.FoodName }}
                      </p>
                      <p
                        class="menu-group-food-memo"
                        :class="[
                          food.Stock <= 0 && food.Stock != null
                            ? 'sell-out'
                            : '',
                        ]"
                        :style="isGrid ? 'height: 18px;' : ''"
                      >
                        {{ food.Memo }}
                      </p>
                    </div>

                    <p
                      class="menu-group-food-price"
                      :class="[
                        food.Stock <= 0 && food.Stock != null ? 'sell-out' : '',
                      ]"
                    >
                      ${{ food.SalPrice }}
                    </p>
                  </div>
                  <div class="menu-list">
                    <div
                      class="menu-list-thumbnail-container"
                      :style="isGrid ? 'width: 100%; height: 146px;' : ''"
                    >
                      <img
                        v-if="food.ImageFileName"
                        class="menu-list-thumbnail"
                        :src="food.ImageFileName"
                        alt="商品"
                      />
                      <div
                        v-if="food.Stock <= 0 && food.Stock != null"
                        class="sell-out-filter"
                        :class="[!food.ImageFileName ? 'non-img' : '']"
                      >
                        {{ $t("menu.soldout") }}
                      </div>
                    </div>

                    <div v-if="food.Stock > 0 || food.Stock == null">
                      <div
                        class="cart-item-qty plus-target"
                        :class="[food.CurrentQty > 0 ? 'has-cart-item' : '']"
                        :data-foodid="food.FoodId"
                      >
                        <div class="cart-item-qty-close plus-target">
                          <div
                            class="plus-target"
                            :class="[food.CurrentQty > 0 ? '' : 'hide']"
                          >
                            {{ food.CurrentQty }}
                          </div>
                          <div
                            class="plus-target"
                            :class="[food.CurrentQty == 0 ? '' : 'hide']"
                          >
                            <i class="fas fa-plus plus-target"></i>
                          </div>
                        </div>
                        <div class="cart-item-qty-open plus-target">
                          <div
                            class="cart-item-qty-remove plus-target"
                            :class="[food.CurrentQty == 1 ? '' : 'hide']"
                            :data-foodid="food.FoodId"
                            @click="removeItem($event, food)"
                          >
                            <i class="fas fa-trash-alt plus-target"></i>
                          </div>
                          <div
                            class="cart-item-qty-minus plus-target"
                            :class="[food.CurrentQty > 1 ? '' : 'hide']"
                            :data-foodid="food.FoodId"
                            @click="minusQty($event, food)"
                          >
                            <i class="fas fa-minus plus-target"></i>
                          </div>
                          <div
                            class="cart-item-qty-count plus-target"
                            @click="$event.stopPropagation()"
                          >
                            {{ food.CurrentQty }}
                          </div>
                          <div
                            class="cart-item-qty-plus plus-target"
                            :class="[
                              food.CurrentQty == food.Stock ? 'limited' : '',
                            ]"
                            :data-foodid="food.FoodId"
                            @click="addQty($event, food)"
                          >
                            <i class="fas fa-plus plus-target"></i>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="menu-cart">
        <button
          v-if="cart.Counts > 0"
          class="btn btn-full btn-border flex space-between items-center"
          @click="goCheckout('adding')"
        >
          <div class="menu-cart-counts">
            <p>{{ cart.Counts }}{{ $t("menu.cartitem") }}</p>
          </div>
          <p>{{ $t("menu.cart") }}</p>
          <p class="menu-cart-total">${{ cart.Total }}</p>
        </button>
        <button v-else class="btn btn-full btn-border">
          <p>{{ $t("menu.pleasechooseyourmeal") }}</p>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import $ from "jquery";
import Order from "../../components/Order.vue";

export default {
  data() {
    return {
      cart: this.$cookie.cart.getDefault(),
      isOrderShow: false,
      orderFood: {},
      store: {},
      table: {},
      foodKindGroups: [],
      currentFoodKindId: "",
      nowToken: this.$cookie.member.getToken(),
      isTimeShow: false,
      hasEdit: true,
      isEdit: true,
      renderKey: 0,
      countdownTimeout: null,
      bgTrigger: false,
      currentRoute: null,
      fromRoute: null,
      currentLang: {},
      isGrid: false,
    };
  },
  components: {
    Order,
  },
  computed: {
    isMultiLanguage() {
      return (
        this.store.HasEnglish ||
        this.store.HasJapanese ||
        this.store.HasKorean ||
        this.store.HasFrench
      );
    },
    phoneLink() {
      return `tel:${this.store.Phone}`;
    },
    memberText() {
      if (this.nowToken) {
        if (this.$cookie.customer.isStoreCustomer(this.$route.params.keyid)) {
          const customer = this.$cookie.member.get();
          return customer.Name;
        } else {
          return this.$t("join.joinstore");
        }
      } else {
        return this.$t("login.login");
      }
    },
  },
  watch: {
    isGrid(value) {
      localStorage.setItem("isGrid", value);
    },
    currentLang(value) {
      this.$i18n.locale = value.lang.toLowerCase();
      this.$api.stores
        .getStatus(this.$route.params.keyid, {
          language_type: this.currentLang.value,
        })
        .then((data) => {
          this.store = data;
          this.$store.dispatch("updateLoading", false);
        })
        .catch((error) => {
          this.$swal(error.data.Message);
        });
    },
    isOrderShow(value) {
      if (value) {
        // $(".order-display").css("overflow", "auto");
        $("body").css("overflow", "hidden");
        window.addEventListener("click", this.clickOnBackground);
      } else {
        // $(".order-display").css("overflow", "hidden");
        $("body").css("overflow", "auto");
        window.removeEventListener("click", this.clickOnBackground);
      }
    },
    isTimeShow(value) {
      if (value) {
        $(".time-display").css("overflow", "auto");
        $("body").css("overflow", "hidden");
      } else {
        $(".time-display").css("overflow", "hidden");
        $("body").css("overflow", "auto");
      }
    },
    foodKinds(value) {
      if (value.length > 0) this.currentFoodKindId = value[0].FoodKindId;
      else this.currentFoodKindId = "";
    },
    currentFoodKindId(value) {
      $("#foodKindList").animate(
        {
          scrollLeft: $(`#${value}_li`).offset().left - 20,
        },
        100
      );
    },
  },
  methods: {
    checkCartItems(kindId) {
      let kindArray = this.cart.Items.filter((item) => {
        return item.FoodKindId == kindId;
      });
      let totalQty = 0;
      kindArray.forEach((item) => {
        totalQty += item.CurrentQty;
      });
      if (totalQty > 0) {
        return totalQty;
      }
    },
    setCurrentLanguage(lang) {
      localStorage.setItem("currentLang", lang);
      this.$store.dispatch("setLanguage", lang);
      this.currentLang = this.$enum.langType.getByLang(lang);
      this.$api.menus
        .get({
          eat_type: 0,
          language_type: this.currentLang.value,
        })
        .then((data) => {
          this.foodKindGroups = data;
          this.setFoodsQty();
          this.$store.dispatch("updateLoading", false);
        });
    },
    clickLanguage(lang) {
      if (this.currentLang.lang.toLowerCase() != lang) {
        this.setCurrentLanguage(lang);
      }
    },
    openLanguageList(e) {
      const btn = e.currentTarget;
      const list = document.querySelector(".language-list");
      btn.classList.add("active");
      list.classList.add("active");
      setTimeout(() => {
        document.addEventListener("click", this.closeLanguageList);
      }, 0);
    },
    closeLanguageList() {
      const btn = document.querySelector(".language-list-button");
      const list = document.querySelector(".language-list");
      btn.classList.remove("active");
      list.classList.remove("active");
      setTimeout(() => {
        document.removeEventListener("click", this.closeLanguageList);
      }, 0);
    },
    clickOnBackground(e) {
      if (e.target.classList.contains("order-background")) {
        this.bgTrigger = false;
        this.isOrderShow = false;
      }
    },
    setFoodsQty() {
      this.foodKindGroups.forEach((foodKindGroup) => {
        foodKindGroup.Foods.forEach((food) => {
          let qty = 0;
          let sameIdItems = this.cart.Items.filter((item) => {
            return item.FoodId == food.FoodId;
          });
          sameIdItems.forEach((item) => {
            qty += item.Qty;
          });
          food.CurrentQty = qty;
        });
      });
    },
    countCartItem(food) {
      let cartItem = this.cart.Items.find((item) => {
        return item.FoodId == food.FoodId;
      });
      if (cartItem) {
        return cartItem.Qty;
      }
      return undefined;
    },
    addItem(cartItem) {
      this.cart = this.$cookie.cart.addItem(this.$route.params.keyid, cartItem);
      this.setFoodsQty();
      this.renderKey++;
      this.bgTrigger = false;
      this.isOrderShow = false;
    },
    addQty(e, food) {
      clearTimeout(this.countdownTimeout);
      e.stopPropagation();
      if (food.CurrentQty == food.Stock) {
        return;
      }
      const ect = document.querySelector(
        `.cart-item-qty[data-foodid="${e.currentTarget.dataset.foodid}"]`
      );
      let item = this.cart.Items.find((i) => {
        return i.FoodId == food.FoodId;
      });
      this.cart = this.$cookie.cart.addQty(
        this.$route.params.keyid,
        item.ItemId
      );
      this.setFoodsQty();
      this.$nextTick(() => {
        this.clickPlusBtn(ect);
      });
    },
    minusQty(e, food) {
      clearTimeout(this.countdownTimeout);
      e.stopPropagation();
      const ect = document.querySelector(
        `.cart-item-qty[data-foodid="${e.currentTarget.dataset.foodid}"]`
      );
      let item;
      item = this.cart.Items.find((i) => {
        return i.FoodId == food.FoodId && i.Memo == "";
      });
      if (!item) {
        item = this.cart.Items.find((i) => {
          return i.FoodId == food.FoodId;
        });
      }
      this.cart = this.$cookie.cart.minusQty(
        this.$route.params.keyid,
        item.ItemId
      );
      this.setFoodsQty();
      this.$nextTick(() => {
        this.clickPlusBtn(ect);
      });
    },
    removeItem(e, food) {
      clearTimeout(this.countdownTimeout);
      e.stopPropagation();
      let item;
      item = this.cart.Items.find((i) => {
        return i.FoodId == food.FoodId && i.Memo == "";
      });
      if (!item) {
        item = this.cart.Items.find((i) => {
          return i.FoodId == food.FoodId;
        });
      }
      this.cart = this.$cookie.cart.removeItem(
        this.$route.params.keyid,
        item.ItemId
      );
      const group = document.querySelector(
        `.cart-item-qty[data-foodid="${food.FoodId}"]`
      );
      const qtyBtns = group.querySelector(".cart-item-qty-open");
      const plusBtn = group.querySelector(".cart-item-qty-close");
      group.classList.remove("active");
      qtyBtns.classList.remove("active");
      plusBtn.classList.remove("active");

      this.setFoodsQty();
    },
    clickPlusBtn(ect) {
      const group = ect;
      const qtyBtns = group.querySelector(".cart-item-qty-open");
      const plusBtn = group.querySelector(".cart-item-qty-close");
      const groups = document.querySelectorAll(".cart-item-qty");
      const groupsArray = Array.from(groups);
      groupsArray.forEach((g) => {
        if (g.dataset.foodid != group.dataset.foodid) {
          const btns = g.querySelector(".cart-item-qty-open");
          const btn = g.querySelector(".cart-item-qty-close");
          g.classList.remove("active");
          btns.classList.remove("active");
          btn.classList.remove("active");
        }
      });

      if (!group.classList.contains("active")) {
        group.classList.add("active");
        setTimeout(() => {
          plusBtn.classList.add("active");
          qtyBtns.classList.add("active");
          this.countdownTimeout = setTimeout(() => {
            group.classList.remove("active");
            qtyBtns.classList.remove("active");
            plusBtn.classList.remove("active");
          }, 5000);
        }, 200);
      } else {
        this.countdownTimeout = setTimeout(() => {
          group.classList.remove("active");
          qtyBtns.classList.remove("active");
          plusBtn.classList.remove("active");
        }, 5000);
      }
    },
    foodClick(e, food) {
      const eventTarget = e.target;
      const eventCurrentTarget = e.currentTarget;
      const ect = eventCurrentTarget.querySelector(
        `.cart-item-qty[data-foodid="${food.FoodId}"]`
      );
      const vm = this;
      if (food.Stock <= 0 && food.Stock != null) return;
      clearTimeout(this.countdownTimeout);
      this.$api.foods
        .get(food.FoodId, { language_type: this.currentLang.value })
        .then((data) => {
          this.orderFood = data;
          if (
            this.orderFood.FoodCombos.length == 0 &&
            this.orderFood.FoodTastes.length == 0 &&
            (eventTarget.nodeName == "path" ||
              eventTarget.classList.contains("plus-target"))
          ) {
            if (!this.cart.Items.find((item) => food.FoodId == item.FoodId)) {
              let newItem = {
                Qty: 1,
                SalPrice: 0,
                Total: 0,
                Tastes: [],
                Combos: [],
                Memo: "",
              };
              newItem.ItemId = this.$utils.uuid.get();
              newItem.FoodKindId = this.orderFood.Food.FoodKindId;
              newItem.FoodId = this.orderFood.Food.FoodId;
              newItem.FoodName = this.orderFood.Food.FoodName;
              newItem.OriginalPrice = this.orderFood.Food.SalPrice;
              newItem.SalPrice = this.orderFood.Food.SalPrice;
              this.$cookie.cart.calculateCombo(newItem);
              this.cart = this.$cookie.cart.addItem(
                this.$route.params.keyid,
                newItem
              );
              this.setFoodsQty();
            }
            this.$nextTick(() => this.clickPlusBtn(ect));
          } else {
            const cart = this.$cookie.cart.get(this.$route.params.keyid);
            let count = 0;
            cart.Items.forEach((item) => {
              if (item.FoodId == food.FoodId) {
                count = count + item.Qty;
              }
            });
            if (food.Stock <= count && food.Stock != null) {
              this.$swal(this.$t("menu.stocklimitreached"));
              this.$store.dispatch("updateLoading", false);
              return;
            }
            this.bgTrigger = true;
            this.isOrderShow = true;
          }
          this.$store.dispatch("updateLoading", false);
        })
        .catch((error) => {
          vm.$swal(error.data.Message);
          vm.closed();
        });
    },
    memberClick() {
      const token = this.$cookie.member.getToken();
      if (token) {
        if (this.$cookie.customer.isStoreCustomer(this.$route.params.keyid)) {
          this.$router.push({
            name: "Member",
          });
        } else {
          this.$router.push({
            name: "Join",
          });
        }
      } else {
        this.$router.push({
          name: "Login",
        });
      }
    },
    goCheckout(list) {
      if (!this.$route.path.includes("instore") && this.cart.Counts == 0) {
        this.$swal(this.$t("menu.pleasechooseyourmeal"));
        return;
      }
      let routeName = "InStoreCheckout";
      if (this.$route.path.includes("tables")) {
        routeName = "InStoreTableCheckout";
      }
      this.$router.push({
        name: routeName,
        query: { list: list },
      });
    },
    changeFoodKind(id) {
      window.removeEventListener("scroll", this.onScroll);
      this.currentFoodKindId = id;
      const vm = this;
      $("html, body").animate(
        {
          scrollTop: $(`#${id}`).offset().top - 120,
        },
        100,
        function () {
          window.addEventListener("scroll", vm.onScroll);
        }
      );
    },
    onScroll(e) {
      let windowTop = e.target.documentElement.scrollTop;
      const vm = this;
      this.foodKindGroups.forEach((foodKindGroup) => {
        const positionToTop = $(
          `#${foodKindGroup.FoodKind.FoodKindId}`
        ).offset().top;
        if (positionToTop - 120 < windowTop) {
          vm.currentFoodKindId = foodKindGroup.FoodKind.FoodKindId;
        }
      });
    },
  },
  created() {
    window.addEventListener("scroll", this.onScroll);
  },
  mounted() {
    // $(".order-display").css("overflow", "hidden");
    $("body").css("overflow", "auto");

    const token = this.$cookie.member.getToken();

    this.isGrid = localStorage.getItem("isGrid") == "true";

    this.store = this.$cookie.store.get(this.$route.params.keyid);
    this.cart = this.$cookie.cart.get(this.$route.params.keyid);

    this.$store.dispatch("setDeviceId", this.store.DeviceId);

    let defaultLang = "zh";
    let localCurrentLang = localStorage.getItem("currentLang");
    let needResetLang =
      (!this.store.HasEnglish && localCurrentLang == "en") ||
      (!this.store.HasJapanese && localCurrentLang == "jp") ||
      (!this.store.HasKorean && localCurrentLang == "kr") ||
      (!this.store.HasFrench && localCurrentLang == "fr");
    if (localCurrentLang && !needResetLang) {
      defaultLang = localCurrentLang;
    }
    this.$i18n.locale = defaultLang;
    localStorage.setItem("currentLang", defaultLang);
    this.$store.dispatch("setLanguage", defaultLang);
    this.currentLang = this.$enum.langType.getByLang(defaultLang);

    if (this.$route.name == "InStoreMenu") {
      localStorage.removeItem("salTempId");
      this.$api.inStore
        .get(this.store.DeviceId, this.$route.params.routeid)
        .then((data) => {
          this.table = data.SalTemp;
          localStorage.setItem("tableNum", this.table.TableMapName);
          localStorage.setItem("salId", this.$route.params.routeid);

          this.$api.menus.get({ eat_type: 0 }).then((data) => {
            this.foodKindGroups = data;
            this.$store.dispatch("updateLoading", false);
          });
          if (!token && this.$store.state.isFirstTime) {
            this.$store.dispatch("updateTime", false);
            this.nowToken = this.$cookie.member.getToken();
          } else {
            this.$store.dispatch("updateTime", false);
          }
        })
        .catch((error) => {
          if (error.status == "404") {
            this.$router.replace({ name: "InStoreInvalid" });
          } else {
            this.$swal(error.data.Message);
          }
        });
    } else if (this.$route.name == "InStoreTableMenu") {
      localStorage.removeItem("salId");

      let salTempId = null;
      if (localStorage.getItem("salTempId")) {
        salTempId = localStorage.getItem("salTempId");
      }

      if (salTempId) {
        this.$api.inStore
          .getLastTable(
            this.store.DeviceId,
            this.$route.params.routeid,
            salTempId
          )
          .then((data) => {
            this.table = data.SalTemp;
            this.$api.menus
              .get({ eat_type: 0, language_type: this.currentLang.value })
              .then((data) => {
                this.foodKindGroups = data;
                this.setFoodsQty();
                this.$store.dispatch("updateLoading", false);
              });
            localStorage.setItem("tableNum", this.table.TableMapName);
            if (localStorage.getItem("salTempId") != this.table.SalTempId) {
              localStorage.setItem("salTempId", this.table.SalTempId);
            }
            if (this.$cookie.table.get() != this.$route.params.routeid) {
              this.$cookie.table.set(this.$route.params.routeid);
            }

            if (this.$store.state.isFirstTime) {
              this.$store.dispatch("updateTime", false);
              this.nowToken = this.$cookie.member.getToken();
            } else {
              this.$store.dispatch("updateTime", false);
            }
          })
          .catch((error) => {
            if (error.status == "404") {
              this.$router.replace({ name: "InStoreInvalid" });
            } else {
              this.$swal(error.data.Message);
            }
          });
      } else {
        this.$api.inStore
          .postNewTable(this.store.DeviceId, this.$route.params.routeid)
          .then((data) => {
            this.table = data.SalTemp;
            this.$api.menus
              .get({ eat_type: 0, language_type: this.currentLang.value })
              .then((data) => {
                this.foodKindGroups = data;
                this.setFoodsQty();
                this.$store.dispatch("updateLoading", false);
              });
            localStorage.setItem("tableNum", this.table.TableMapName);
            if (localStorage.getItem("salTempId") != this.table.SalTempId) {
              localStorage.setItem("salTempId", this.table.SalTempId);
              localStorage.setItem("tableStartTime", new Date().getTime());
            }
            if (this.$cookie.table.get() != this.$route.params.routeid) {
              this.$cookie.table.set(this.$route.params.routeid);
            }

            if (this.$store.state.isFirstTime) {
              this.$store.dispatch("updateTime", false);
              this.nowToken = this.$cookie.member.getToken();
            } else {
              this.$store.dispatch("updateTime", false);
            }
          })
          .catch((error) => {
            if (error.status == "404") {
              this.$router.push({ name: "NotFound" });
            } else {
              this.$swal(error.data.Message);
            }
          });
      }
    }
  },
  beforeRouteUpdate(to, from, next) {
    this.currentRoute = to.fullPath;
    this.fromRoute = from.fullPath;
    next();
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.onScroll);
  },
};
</script>
