import Vue from "vue";
import VueRouter from "vue-router";
import store from "@/store";
import enums from "@/enums";
import cookie from "@/cookie";
import api from "@/requests/api";
import liff from "@line/liff";
import i18n from "@/langs/i18n";

import Register from "../views/Register.vue";
import Login from "../views/Login.vue";
import ForgetPassword from "../views/ForgetPassword.vue";
import Join from "../views/Join.vue";
import Member from "../views/Member.vue";
import MemberEdit from "../views/MemberEdit.vue";
import PasswordEdit from "../views/PasswordEdit.vue";
import Menu from "../views/Menu.vue";
import Checkout from "../views/Checkout.vue";
import Feedback from "../views/Feedback.vue";
import Callback from "../views/Callback.vue";
import CheckPhoneVerify from "../views/CheckPhoneVerify.vue";

import Trade from "../views/Trade.vue";

import Empty from "../views/Empty.vue";
import Error from "../views/Error.vue";

import StoreList from "../views/StoreList.vue";

import HistoryOrders from "../views/HistoryOrders.vue";
import Liff from "../views/Liff.vue";
import Swal from "sweetalert2/dist/sweetalert2";

import InStore from "../views/instore/InStore.vue";
import InStoreMenu from "../views/instore/InStoreMenu.vue";
import InStoreCheckout from "../views/instore/InStoreCheckout.vue";

import InStoreTableJumpPage from "../views/instore/InStoreTableJumpPage.vue";

import InStoreInvalid from "../views/InStoreInvalid.vue";
import PaymentCancel from "../views/PaymentCancel.vue";
import PaymentConfirming from "../views/PaymentConfirming.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    component: StoreList,
    name: "Storelist",
  },
  {
    path: "/liff",
    component: Empty,
    children: [
      {
        path: "",
        name: "Liff",
        component: Liff,
      },
      {
        path: ":keyid",
        name: "Redirection",
        component: Liff,
      },
    ],
  },
  {
    path: "/:keyid",
    component: Empty,
    props: true,
    children: [
      {
        path: "",
        name: "Menu",
        component: Menu,
      },
      {
        path: "checkout",
        name: "Checkout",
        component: Checkout,
      },
      {
        path: "member",
        name: "Member",
        component: Member,
      },
      {
        path: "member/edit",
        name: "MemberEdit",
        component: MemberEdit,
      },
      {
        path: "member/password/edit",
        name: "PasswordEdit",
        component: PasswordEdit,
      },
      {
        path: "trade/:id",
        name: "Trade",
        component: Trade,
      },
      {
        path: "register",
        name: "Register",
        component: Register,
      },
      {
        path: "login",
        name: "Login",
        component: Login,
      },
      {
        path: "join",
        name: "Join",
        component: Join,
      },
      {
        path: "forget-password",
        name: "ForgetPassword",
        component: ForgetPassword,
      },
      {
        path: "history-orders",
        name: "HistoryOrders",
        component: HistoryOrders,
      },
      {
        path: "feedback",
        name: "Feedback",
        component: Feedback,
      },
      {
        path: "callback",
        name: "Callback",
        component: Callback,
      },
      {
        path: "checkphoneverify",
        name: "CheckPhoneVerify",
        component: CheckPhoneVerify,
      },
      {
        path: "instore/:routeid",
        component: InStore,
        children: [
          {
            path: "",
            name: "InStoreMenu",
            component: InStoreMenu,
          },
          {
            path: "checkout",
            name: "InStoreCheckout",
            component: InStoreCheckout,
          },
        ],
      },
      {
        path: "instore/tables/:routeid",
        component: InStore,
        children: [
          {
            path: "",
            name: "InStoreTableJumping",
            component: InStoreTableJumpPage,
          },
          {
            path: "menu",
            name: "InStoreTableMenu",
            component: InStoreMenu,
          },
          {
            path: "checkout",
            name: "InStoreTableCheckout",
            component: InStoreCheckout,
          },
        ],
      },
      {
        path: "invalid",
        name: "InStoreInvalid",
        component: InStoreInvalid,
      },
      {
        path: "paymentcancel",
        name: "PaymentCancel",
        component: PaymentCancel,
      },
      {
        path: "paymentconfirming",
        name: "PaymentConfirming",
        component: PaymentConfirming,
      },
    ],
  },

  {
    path: "*",
    redirect: { name: "NotFound" },
  },
  {
    name: "NotFound",
    path: "/not-found",
    component: Error,
  },
];

const router = new VueRouter({
  mode: "history",
  // base: process.env.BASE_URL,
  routes,
});

const originalPush = VueRouter.prototype.push;

VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
};

router.beforeEach(async (to, from, next) => {
  store.dispatch("setPATH", from.name);

  let url = new URL(decodeURIComponent(window.location.href));
  let wholeParam = url.searchParams;
  let dTO = {
    language_type: 0,
  };
  if (localStorage.getItem("currentLang")) {
    i18n.locale = localStorage.getItem("currentLang").toLowerCase();
    dTO.language_type = enums.langType.getByLang(
      localStorage.getItem("currentLang")
    ).value;
  }

  if (wholeParam.has("liff.state")) {
    let param = wholeParam.get("liff.state");
    let keyId = param.substring(1, 13);
    try {
      await api.stores.getStatus(keyId, dTO).then(() => {
        liff.init({ liffId: cookie.store.get(keyId).LineLiffId });
        store.dispatch("updateLoading", false);
        return;
      });
    } catch (err) {
      Swal.fire("發生錯誤 !", err, "error").then((result) => {
        if (result.value) {
          next({ name: "NotFound" });
        }
      });
    }
    return;
  } else {
    localStorage.setItem("currentStore", to.params.keyid);
    const token = cookie.member.getToken();
    const customer = cookie.customer.get(to.params.keyid);
    if (to.params.keyid) {
      store.dispatch("setKeyId", to.params.keyid);
      if (!from.name) {
        try {
          await api.stores.getStatus(to.params.keyid, dTO);
          if (token) {
            try {
              const data = await api.customers.get();
              store.dispatch("updateLoading", false);
              cookie.customer.set(to.params.keyid, data);
            } catch (error) {
              if (error.response && error.response.status === 401) {
                cookie.member.remove();
                cookie.member.clearId();
                store.dispatch("updateLoading", false);
              } else {
                store.dispatch("updateLoading", false);
              }
            }
          } else {
            store.dispatch("updateLoading", false);
          }
        } catch (err) {
          // Swal.fire("發生錯誤 !", err, "error").then((result) => {
          //   if (result.value) {
          //     next({ name: "NotFound" });
          //   }
          // });
          // return;
        }
      } else {
        try {
          await api.stores.getStatus(to.params.keyid, dTO);
          if (token && (!customer || !customer.Name)) {
            try {
              const data = await api.customers.get();
              store.dispatch("updateLoading", false);
              cookie.customer.set(to.params.keyid, data);
            } catch (error) {
              if (error.response && error.response.status === 401) {
                cookie.member.remove();
                cookie.member.clearId();
                store.dispatch("updateLoading", false);
              } else {
                store.dispatch("updateLoading", false);
              }
            }
          } else {
            store.dispatch("updateLoading", false);
          }
        } catch (err) {
          // Swal.fire("發生錯誤 !", err, "error").then((result) => {
          //   if (result.value) {
          //     next({ name: "NotFound" });
          //   }
          // });
          // return;
        }
      }
    }
    next();
  }
});

export default router;
